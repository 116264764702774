<template>
  <div class="video-html">
    <video
      ref="videoElement"
      :src="props.videoUrl"
      :controls="props.controls"
      :muted="props.muted"
      :loop="props.loop"
      playsinline
      :autoplay="props.autoplay"
      :preload="props.preload"
    />
  </div>
</template>

<script>
export default {
  name: 'WidgetVideo',
}
</script>

<script setup>
const props = defineProps({
  videoUrl: String,
  autoplay: Boolean,
  muted: {
    type: Boolean,
    default: false,
  },
  preload: {
    type: String,
    default: 'none',
  },
  controls: {
    type: Boolean,
    default: false,
  },
  loop: Boolean,
  playInViewport: {
    type: Boolean,
    default: false,
  },
  forcePlay: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['play', 'pause', 'videoStarted', 'videoEnded'])

const videoElement = ref(null)
let observer = null

const onPlay = () => {
  emit('play')
}

const onPlaying = () => {
  emit('videoStarted')
}

const onEnded = () => {
  emit('videoEnded')
}

onMounted(() => {
  if (videoElement.value) {
    videoElement.value.addEventListener('play', onPlay)
    videoElement.value.addEventListener('playing', onPlaying)
    videoElement.value.addEventListener('ended', onEnded)
  }

  if (props.playInViewport) {
    playerObserver()
  }
})

onUnmounted(() => {
  if (videoElement.value) {
    videoElement.value.removeEventListener('play', onPlay)
    videoElement.value.removeEventListener('playing', onPlaying)
  }

  if (observer) {
    observer.disconnect()
  }
})

watch(
  () => props.playInViewport,
  (playInViewport) => {
    if (playInViewport) {
      playerObserver()
    }
  }
)

watch(
  () => props.forcePlay,
  (forcePlay) => {
    var playPromise = videoElement.value?.play()
    if (forcePlay) {
      videoElement.value?.play()
    } else {
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            videoElement.value?.pause()
          })
          .catch((error) => {
            console.log('error watch ')
            console.log(error)
          })
      }
    }
  }
)

const playerObserver = () => {
  observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        var playPromise = entry.target.play()
        if (entry.isIntersecting) {
          emit('play')
          entry.target.play()
        } else {
          if (playPromise !== undefined) {
            playPromise
              .then((_) => {
                emit('pause')
                entry.target.pause()
              })
              .catch((error) => {
                console.log('error observer ')
                console.log(error)
              })
          }
        }
      })
    },
    {
      threshold: 0.1,
    }
  )

  observer.observe(videoElement.value)

  document.addEventListener('visibilitychange', () => {
    var playPromise = videoElement.value?.play()
    if (document.hidden) {
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            videoElement.value?.pause()
          })
          .catch((error) => {
            console.log('error promise in listener visibility ')
            console.log(error)
          })
      }
    } else {
      videoElement.value?.play()
    }
  })
}

defineExpose({
  videoElement,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
